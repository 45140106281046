import type { ProgramType } from '@tu/types/program';
import type { Role } from '@tu/types/user';

const dictionary = {
  MentorKey: {
    mentor: 'MentorKey',
    sponsor: 'SponsorKey',
    coach: 'MentorKey',
  },
  mentoring: {
    mentor: 'mentoring',
    sponsor: 'sponsoring',
    coach: 'coaching',
  },
  Mentoring: {
    mentor: 'Mentoring',
    sponsor: 'Sponsoring',
    coach: 'Coaching',
  },
  Mentor: {
    mentor: 'Mentor',
    sponsor: 'Sponsor',
    coach: 'Coach',
  },
  Mentee: {
    mentor: 'Mentee',
    sponsor: 'Sponsee',
    coach: 'Coachee',
  },
  mentor: {
    mentor: 'mentor',
    sponsor: 'sponsor',
    coach: 'coach',
  },
  "mentor's": {
    mentor: "mentor's",
    sponsor: "sponsor's",
    coach: "coach's",
  },
  'mentor’s': {
    mentor: 'mentor’s',
    sponsor: 'sponsor’s',
    coach: 'coach’s',
  },
  mentee: {
    mentor: 'mentee',
    sponsor: 'sponsee',
    coach: 'coachee',
  },
  mentorship: {
    mentor: 'mentorship',
    sponsor: 'sponsorship',
    coach: 'coaching',
  },
};

const __ = (term: string, program_type: ProgramType = 'mentor') => {
  // Check if the object key exists
  if ((dictionary as any)[term]) {
    return (dictionary as any)[term][program_type];
  }

  return term;
};

export const getRoleLabel = (
  role: Role,
  programType: ProgramType,
  upperCase: boolean = true,
  plural: boolean = false,
) => {
  const dictionary = {
    mentor: {
      mentor: 'Mentor',
      mentee: 'Mentee',
    },
    sponsor: {
      mentor: 'Sponsor',
      mentee: 'Sponsee',
    },
    coach: {
      mentor: 'Coach',
      mentee: 'Coachee',
    },
  };

  const output = upperCase ? dictionary[programType][role] : dictionary[programType][role].toLowerCase();

  return plural ? output + 's' : output;
};

export const translate = (string: string, programType: ProgramType = 'mentor') => {
  let output = string;

  if (!output) {
    return string;
  }

  // Replace each key present in the dictionary
  for (const key in dictionary) {
    // Make sure the key is a word and not part of a URL
    // Make sure the key is not preceded or followed by a hyphen -
    const regex = new RegExp(`(?<![-/])\\b${key}\\b(?![-/])`, 'g');
    output = output.replaceAll(regex, __(key, programType));
  }

  for (const key in dictionary) {
    // Replaces all escaped key words with the key, eg. /mentoring/ -> mentoring (without translating)
    const regex = new RegExp(`\/${key}\/`, 'g');
    output = output.replaceAll(regex, key);
  }

  return output;
};

export default __;
